import React from "react"; 


 
export default class ErrorPage extends React.Component {
  constructor(props){
    super(props);
    this.state={
      errorMessage:"",
    }
  }

  componentDidMount(){
    console.log("***this.props.match.params.errorMessage="+this.props.match.params.errorMessage)
    var errorMessage = this.props.match.params.errorMessage;
     if(errorMessage!==null){
        this.setState({errorMessage});
      }
      // const url = window.location.href;
      // const params = new URLSearchParams(search);
      // const errorMessage = params.get("errorMessage");
      // console.log("***errorMessage="+JSON.stringify(errorMessage))
      // if(errorMessage!==null){
      //   this.setState({errorMessage:JSON.stringify(errorMessage)});
      // }
  }
  
render(){
  return ( 
    <div style={{height:"100%",width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
      {/* <div style={{height:"50%"}}><img src="../../images/login.png" height="100"/></div> */}
      {/* <div style={{height:"70%"}}> */}
      <h4>{this.state.errorMessage}</h4> 
      <p> 
       Please contact us 
        at &nbsp;
        <a href="mailto:support@intelisa.in">support@intelisa.in</a>. 
      </p> 
      {/* </div> */}
    </div> 
  ); 
} 

 
}