import React from "react";
import { Redirect } from 'react-router-dom'
import {logout} from "../../redux/actions/authAction.js"
import { googleLogout } from '@react-oauth/google';
import {connect} from "react-redux";


@connect((store)=>{
  return {

  }
})
export default class Logout extends React.Component {
  constructor(props){
super(props);

  }

componentWillMount(){
  console.log("b4 google logout")
  googleLogout();
  this.props.dispatch(logout())
}


  render() {
    if(!this.props.isAuthenticated){
      return(
      <Redirect to={{
       pathname: "/login"
     }}/>)
    }
    return null;
  }
}
