import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';


import Layout from "./Layout";
import Login from "./Auth/Login.js"
import XLogin from "./Auth/XLogin.js"
import ErrorPage from "./Auth/ErrorPage.js"
import Logout from "./Auth/Logout.js"
import MyPlan from '../components/Auth/myplan.js';
import Success from "./Auth/Success.js"
import Cancel from "./Auth/Cancel.js"
import  PrivateRoute  from "../components/Auth/PrivateRoute"

class AppContainer extends Component {
  componentDidMount(){
    console.log("*****iin AppContainer")
  }
  render() {
    return (
      
      <HashRouter>
        <Switch>
          <Route path="/login" name="Login" component={Login} />
          <Route path="/xlogin" name="XLogin" component={XLogin} />
          <Route path="/errorPage/:errorMessage" name="ErrorPage" component={ErrorPage} />
          <Route path="/logout" name="Logout" component={Logout} />
          <Route path="/myplans" name="MyPlan" component={MyPlan} />{/* To redirect from Stripe to My Plan page */}
          {/* <Route path="/success" name="Success" component={Success} />
          <Route path="/cancel" name="Cancel" component={Cancel} /> */}
           <PrivateRoute  path="/" component={Layout} />
        </Switch>        
      </HashRouter>
    );
  }
}

export default AppContainer;
