import React from "React";
import {NavLink} from 'react-router-dom';

import {  Trans } from 'react-i18next';
import i18n from '../../i18n';
import {ROLEMAP,CONTROL_PANEL_PERMISSIONS,LANGUAGES} from "../../../constants/CONSTANTS"
import "../../../css/navbar/bootstrapNavbarAll.css";
import {setLocaleForUser} from '../../redux/actions/authAction';
import {updatePagesVisited} from '../../redux/actions/userAdminAction';
import {playlisttour} from "../tutorial/playlisttutorial.js";
import  Breakpoint  from '../uilib/Breakpoint'
import "../../../css/tutorial.css";
const permissions = ["overview","myscreens","myads","scheduler","playlist"];
import { hashHistory } from 'react-router';
import {connect} from "react-redux";
@connect((store)=>{
  return {
    
  }
})
export default class NavbarBootAll extends React.Component{

constructor(props){
  super(props);
  this.activateSearch=this.activateSearch.bind(this)
  this.state={
    searchText:'inactive',
    controlPanelPermissionsExist : false,
    lang:"",
    showDiv:false
  }
}

componentDidMount(){
  var userPermissions = JSON.parse(localStorage.getItem("userObj")).permissions;
  var controlPanelPermissionsExist = false;
  userPermissions.map((obj)=>{
    if(CONTROL_PANEL_PERMISSIONS.includes(obj)){
      controlPanelPermissionsExist = true;
    }
  });
  this.setState({controlPanelPermissionsExist});

  //If Delete of screen is not completed, redirect user to My plans
  if(JSON.parse(localStorage.getItem("userObj")).stripeOperationCompleted=='delete' 
|| JSON.parse(localStorage.getItem("userObj")).permissions.length==0){
    this.goToMyPlans();
  }

}

activateSearch(e){
  if(e.target.value!=''){
      this.setState({searchText:'active'})
  }else{
      this.setState({searchText:'inactive'})

  }

}
launchBuilder = () =>{
  this.props.handler(true)
}

handleChangeLang=(lang)=>{
  console.log("************this changes the lang!")
  //this.setState({locale:"fr"})
  //this.setState({ locale: "en" });
  // store.dispatch({type: "SET_LOCALE"})
  // localStorage.setItem("locale","fr");
  // store.dispatch(updateIntl({
  //   locale:'fr',
  //   French,
  // }))
  // var lang = localStorage.getItem("i18nextLng");
  // if(lang==null || lang=="fr"){
  //   localStorage.setItem("i18nextLng","en");
  //   i18n.changeLanguage('en');
  // }else{
  //   localStorage.setItem("i18nextLng","fr");
  //   i18n.changeLanguage('fr');
  // }
  // var lang = localStorage.getItem("locale");
  // console.log("lang="+lang)
  if(lang=="en"){
    i18n.changeLanguage('fr');
    localStorage.setItem("locale","fr");
    this.props.dispatch(setLocaleForUser('fr'));
    //this.setState({lang:'fr'})
  }else{
    i18n.changeLanguage('en');
    localStorage.setItem("locale","en");
    this.props.dispatch(setLocaleForUser('en'));
    //this.setState({lang:'en'})
  }
  // i18n.changeLanguage(lang);
  // localStorage.setItem("locale",lang);
  //this.props.dispatch(setLocaleForUser(lang));
  window.location.reload(true);//to refresh current page
  
  console.log("***href="+window.location.href)
  //window.location.href=window.location.origin;
}

changeStyle1OnHover = () =>{
  var changeLang = document.getElementById("changeLang");
  changeLang.style.textDecoration = "underLine";
  changeLang.style.color = "white";
  this.setState({showDiv:true});
}

changeStyleTutorialOnHover = () =>{
  var tutorial = document.getElementById("tutorial");
  tutorial.style.textDecoration = "underLine";
  tutorial.style.color = "white";
  this.setState({showDiv:true});
}

changeStyleMyPlanOnHover = () =>{
  var tutorial = document.getElementById("myPlan");
  tutorial.style.textDecoration = "underLine";
  tutorial.style.color = "white";
  this.setState({showDiv:true});
}

changeStyle1Back = () =>{
  var changeLang = document.getElementById("changeLang");
  changeLang.style.textDecoration = "none";
  changeLang.style.color = "#9a9db7";
}
changeStyleTutorialBack = () =>{
  var tutorial = document.getElementById("tutorial");
  tutorial.style.textDecoration = "none";
  //changeLang.style.color = "#007bff";
  tutorial.style.color = "#9a9db7";
  //this.setState({showDiv:false});
}

changeStyleMyPlanBack = () =>{
  var tutorial = document.getElementById("myPlan");
  tutorial.style.textDecoration = "none";
  //changeLang.style.color = "#007bff";
  tutorial.style.color = "#9a9db7";
  //this.setState({showDiv:false});
}
changeStyle2OnHover = () =>{
  var signout = document.getElementById("signout");
  signout.style.textDecoration = "underLine";
  signout.style.color = "white";
}

changeStyle2Back = () =>{
  var signout = document.getElementById("signout");
  signout.style.textDecoration = "none";
  signout.style.color = "#9a9db7";
}

goToMyPlans = () =>{
  // console.log("ZTo go to My plans!");
  window.location.href=window.location.origin+"/#/myplans";
}

clickOfPlaylist = ()=>{
  console.log("USER OBJ="+localStorage.getItem("userObj"))
console.log("***PAGESVISITED="+JSON.parse(localStorage.getItem("userObj")).pagesVisited)
var playlistPageVisited = JSON.parse(localStorage.getItem("userObj")).pagesVisited.playlist== undefined || JSON.parse(localStorage.getItem("userObj")).pagesVisited.playlist==null?
0: JSON.parse(localStorage.getItem("userObj")).pagesVisited.playlist;
console.log("****playlistPageVisited="+playlistPageVisited);
// if(playlistPageVisited==0 || playlistPageVisited==1){
//   playlisttour.start();
//   this.props.dispatch(updatePagesVisited(JSON.parse(localStorage.getItem("userObj")).userID,{playlist:++playlistPageVisited}));
// }


}
clickOfMyScreens = ()=>{
  var pagesVisitedObj = JSON.parse(localStorage.getItem("userObj")).pagesVisited;
  console.log("USER OBJ="+localStorage.getItem("userObj"))
console.log("***PAGESVISITED="+JSON.parse(localStorage.getItem("userObj")).pagesVisited)
var myscreensPageVisited = JSON.parse(localStorage.getItem("userObj")).pagesVisited.myscreens?
JSON.parse(localStorage.getItem("userObj")).pagesVisited.myscreens:0;
console.log("****myscreensPageVisited="+myscreensPageVisited);
if(myscreensPageVisited==0){
  this.props.dispatch(updatePagesVisited(JSON.parse(localStorage.getItem("userObj")).userID,{...pagesVisitedObj,myscreens:++myscreensPageVisited}));
}


}
  render(){
    return (
      <nav class="navbar no-icon-nav navbar-expand-lg navbar-dark bg-dark bd-navbar">
    <a class="navbar-brand h-100" href="#" style={{borderRadius:"5px"}}>
    <img src={JSON.parse(localStorage.getItem("userObj")).userID=="609e0bf633ee003a7c661e29"?"../../images/beyondthesurface.png":"../../images/logo.png"} height="100%" width="120px" />
    </a>
      <NavLink to="/logout" class="navbar-toggler-logout d-md-none" style={{position:"absolute",right:"10px"}}><i class="fa fa-circle-o"></i> Sign Out</NavLink>

      {/* <Breakpoint name="desktop"> */}
    <div class="navbar-nav-scroll navbar-collapse pl-5" id="navbarTogglerDemo02">
      <ul class="navbar-nav mr-auto mt-2 bd-navbar-nav mt-lg-0 navbar-navigation-menu">
      {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("overview")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/overview"><i class="fas fa-columns"></i> <span><Trans i18nKey="titlebar.overview"/>
           </span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("myscreens")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to={{pathname:"/myscreen", state:{
            option:"none"
          }}} onClick={()=>this.clickOfMyScreens()}><i class="fas fa-desktop"></i> <span><Trans i18nKey="titlebar.myscreens">
            
          </Trans></span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("myads")!==-1?
        <li class="nav-item" id="myads">
          <NavLink class="nav-link" activeClassName="active" to="/myads/null"> <span>{JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("usealias")!==-1?<Trans i18nKey="titlebar.mynotices"/>:<Trans i18nKey="titlebar.myads"/>}</span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("designlayout")!==-1? 
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/designnew"><i class="fas fa-columns"></i> <span><Trans i18nKey="titlebar.design"/></span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("playlist")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/playlist" onClick={()=>this.clickOfPlaylist()}><i class="fas fa-columns"></i> <span><Trans i18nKey="titlebar.playlist"/></span></NavLink>
        </li>
        :null}
         {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("confroomscheduler")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/confroomscheduler"><i class="fa fa-chart-bar"></i> <span>Conference Room Scheduler</span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("scheduler")!==-1?
        <li class="nav-item">
          <NavLink id="schedulerTab" class="nav-link" activeClassName="active" to="/scheduler"><i class="fa fa-chart-bar"></i> <span><Trans i18nKey="titlebar.scheduler"/></span></NavLink>
        </li>
        :null}
        
        
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("onoffreport")!==-1 || JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("adreport")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/reports"><i class="fa fa-chart-bar"></i> <span><Trans i18nKey="titlebar.reports"/></span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).userID=="648d5de234778d38cb76dfeb"?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/todayprice"><i class="fas fa-columns"></i> <span><Trans i18nKey="titlebar.todayprice"/></span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("tagupload")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/tagupload"><i class="fas fa-columns"></i> <span><Trans i18nKey="titlebar.tagupload"/></span></NavLink>
        </li>
        :null}
        {
          this.state.controlPanelPermissionsExist?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/admin"><i class="fas fa-file-alt"></i> <span ><Trans i18nKey="titlebar.controlpanel"/></span></NavLink>

        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("usermgt")!==-1 || JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("assignscreens")!==-1 
        || JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("schedulemgt")!==-1 || JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("accesspermissions")!==-1
        || JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("rolemanagement")!==-1 || JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("approver")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/adminoptions"><i class="fas fa-file-alt"></i> <span><Trans i18nKey="titlebar.admin"/></span></NavLink>
        </li>
        :        
        null}

      

      </ul>
      <ul class="navbar-nav ml-auto action-items d-none d-md-flex">
            <li class="nav-item  d-none  align-items-center justify-content-center" >
            <div class="navbar-search">
            <button type="submit" class="btn btn-search">
            <i class="fa fa-search">

          </i>
            </button>
        <input type="text" name="search" placeholder="search" />


      </div>
            </li>

            <li class="nav-item">
            <a class="nav-link user-avatar d-inline-flex align-items-center" >
            <span class="avatar w-32"><i class="fas fa-user-circle" style={{fontSize:"30px"}}></i></span>
            <span class="nav-text d-flex flex-column pointer pl-2 text-left" style={{lineHeight:"1"}}><span >{JSON.parse(localStorage.getItem("userObj")).userName}&nbsp;{/*JSON.parse(localStorage.getItem("userObj")).lastName*/}</span>
            <small class="text-uppercase">{JSON.parse(localStorage.getItem("userObj")).userRole}</small></span>{/*ROLEMAP[JSON.parse(localStorage.getItem("userObj")).role]*/}

            </a>
            <div class="treeview-menu-block">
            
        
         <ul class="treeview-menu">

        {/* <li>
        <div  id="changeLang" activeClassName="active" 
        style={{height:"10px", color:'#9a9db7',textDecoration: "none",backgroundColor: "transparent",cursor: "pointer",fontSize:"14px",marginLeft:"14px"}}
        onMouseOver={()=>this.changeStyle1OnHover()} onMouseOut={()=>this.changeStyle1Back()} onClick={()=>this.handleChangeLang(localStorage.getItem("locale"))}>
        
        <Trans i18nKey="menu.changelang"/>
        </div>  */}
        {/* {this.state.showDiv? 
       <ul>
       {
         LANGUAGES.map((obj)=>{
          if(localStorage.getItem("locale")!==null && obj.value!==localStorage.getItem("locale"))
          return <li>
          <div id={"lang_"+obj.value} key={"lang_"+obj.value} onMouseOver={()=>this.changeStyle2OnHover(obj.value)} onClick={()=>this.handleChangeLang(obj.value)} onMouseOut={()=>this.changeStyle2Back(obj.value)}  
        style={{position:"relative",color:'#007bff',textDecoration: "none",backgroundColor: "transparent",cursor: "pointer",fontSize:"14px"}}>
        {obj.label}</div>
        </li>
         })
       }
          
       </ul>
        :null} */}
        {/* </li> */}
        {JSON.parse(localStorage.getItem("userObj")).role=="001"?
        <li class="nav-item">
        {/* <div  id="tutorial" activeClassName="active" 
        style={{height:"10px", color:'#9a9db7',textDecoration: "none",backgroundColor: "transparent",cursor: "pointer",fontSize:"14px",marginLeft:"14px",paddingTop:"22px"}}
        onMouseOver={()=>this.changeStyleTutorialOnHover()} onMouseOut={()=>this.changeStyleTutorialBack()} onClick={()=>this.startTour()}>
        
        Tutorial
        </div> */}
        <div  id="myPlan" activeClassName="active" 
        style={{height:!JSON.parse(localStorage.getItem("userObj")).emailId.includes('adani.com')?"10px":"20px", color:'#9a9db7',textDecoration: "none",backgroundColor: "transparent",cursor: "pointer",fontSize:"14px",marginLeft:"14px",}} //change paddingTop from 2px
        onMouseOver={()=>this.changeStyleMyPlanOnHover()} 
        onMouseOut={()=>this.changeStyleMyPlanBack()} 
        onClick={()=>this.goToMyPlans()}>
        
        My Plan
        </div>
        </li>
        :null}
        {!JSON.parse(localStorage.getItem("userObj")).emailId.includes('adani.com')?
        <li class="nav-item">
        <i class="fa fa-circle-o"></i>
        <NavLink to="/logout" activeClassName="active" id="signout" style={{color:"#9a9db7"}} onMouseOver={()=>this.changeStyle2OnHover()} onMouseOut={()=>this.changeStyle2Back()}> 
        <Trans i18nKey="menu.signout"/></NavLink></li>:null}
      </ul>
      </div>
            </li>

            </ul>


    </div>
    {/*</Breakpoint>
     <Breakpoint name="phone">
    <div class="navbar-nav-scroll navbar-collapse pl-5" id="navbarTogglerDemo02">
      <ul class="navbar-nav mr-auto mt-2 bd-navbar-nav mt-lg-0 navbar-navigation-menu">
      {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("overview")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/overview"><i class="fas fa-columns"></i> <span><Trans i18nKey="titlebar.overview"/>
           </span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("myscreens")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to={{pathname:"/myscreen", state:{
            option:"none"
          }}} ><i class="fas fa-desktop"></i> <span><Trans i18nKey="titlebar.myscreens">
            
          </Trans></span></NavLink>
        </li>
        :null}
        </ul>
        </div>
        </Breakpoint> */}
  </nav>
)
  }
}
