import update from 'immutability-helper';
export default function reducer(state = {

  usersFetching: false,
  usersFetched: false,
isUpdated:false,
  users: [],
  usersForRoleFetched: false,
  usersForRoleFetching: false,
  usersForRole:[],
  addUserStatus:null,
  deviceIPsFetched: false,
  deviceIPsFetching: false,
  softwareVersionsFetched: false,
  softwareVersionsFetching: false,
  devices: [],
  softwareversions:[],
  updateDeviceIPStatus:null,
  updateSoftwareVersionStatus:null,
  screensLevel1Fetched: true,
  screensLevel1Fetching: false,
  screensLevel1:null,
  s3LogFilesFetched: false,
  s3LogFilesFetching: false,
  s3LogFiles:[],
  savingPlaylist:false,
  savedPlaylist:false,
  selectedPlaylist:null,
  addPlaylistNameStatus:0,
  playlists:[],
  playlistsFetching:false,
  playlistsFetched:false,
  generatingGraph:false,
  graphGenerated:false,
  salesUploadDataFetching:false,
  salesUploadDataFetched:false,
  salesUploadData:[],
  gettingExcludeAdsFile:false,
  gotExcludeAdsFile:false,
  gotManagerForUser:false,
  gotUsersForAccess:false,
  deletedPlaylist:false,
  selectedTwitterPlaylist:null,
  selectedInstagramPlaylist:null,
  roles : [],
  rolesFetched : false,
  addRoleStatus:null,
  savingLayout:false,
  savedLayout:false,
  addPLayoutNameStatus:0,
  layouts:[],
  layoutsFetching:false,
  layoutsFetched:false,
  deletedDesign : false,
  addDesignNameStatus:-1,
  managerID:null,
  appVersionFetched : false,
  appVersion:null,
  monitorVersionFetched:false,
  monitorVersion:null,
  currencies:[],
  currenciesForCustomer:[],
  myPlan:{},
  selectedDataForUpdatedPayment:null,
  addDesignLayout:-1,
  selectedDesignId:"",
  operation:"",
  viewPortrait:false,
  approvers:[],
  saveTransaction:false,
  contentApprovalsFetched:false,
  contentApprovals:[],
  adForApprovalFetched:false,
  adForApproval : null,
  playlistsForApprovalFetched:false,
  playlistsForApproval:[],
  playlistApprovalsFetched:false,
  playlistApprovals:[],
  playlistForApprovalFetched:false,
  playlistForApproval:null,
  scheduleApprovalsFetched:false,
  scheduleApprovals:[],
  scheduleForApprovalFetched:false,
  scheduleForApproval:null,
  onlineUserDetails:null,
}, action) {

  switch (action.type) {

      case "FETCH_USERS_ADMIN":
      return {
        ...state,
        usersFetched: false,
        usersFetching: true
      }

      case "FETCH_USERS_ADMIN_SUCCESS":
    var data=[];
      if(action.payload.length>0){
        
       data= action.payload
      }else {
        data=[]
      }
      return {
        ...state,
        usersFetched: true,
        usersFetching: false,
        users:data
      }

      case "FETCH_USERS_FOR_ROLE":
      return {
        ...state,
        usersForRoleFetched: false,
        usersForRoleFetching: true
      }

      case "FETCH_USERS_FOR_ROLE_SUCCESS":
       return {
        ...state,
        usersForRoleFetched: true,
        usersForRoleFetching: false,
        usersForRole:action.payload,
        addUserStatus:0
      }


      case "FETCH_DEVICEIPS_ADMIN":
      return {
        ...state,
        deviceIPsFetched: false,
        deviceIPsFetching: true
      }

      case "FETCH_DEVICEIPS_ADMIN_SUCCESS":
    var data=[];
      if(action.payload.length>0){
       data= action.payload
      }else {
        data=[]
      }
      return {
        ...state,
        deviceIPsFetched: true,
        deviceIPsFetching: false,
        devices:data
      }

      case "FETCH_SOFTWARE_VERSIONS":
        return {
          ...state,
          softwareVersionsFetched: false,
          softwareVersionsFetching: true
        }
  
        case "FETCH_SOFTWARE_VERSIONS_SUCCESS":
      var data=[];
        if(action.payload.length>0){
         data= action.payload
        }else {
          data=[]
        }
        return {
          ...state,
          softwareVersionsFetched: true,
          softwareVersionsFetching: false,
          softwareversions:data
        }      

      case "ADD_USER":{
        return {
          ...state,
          addUserStatus:0
        }
      }
      case "ADD_USER_SUCCESS":{

        var newData=action.payload.data;


        return {
          ...state,
          addUserStatus:1,

          users:[...state.users,newData]
        }
      }
      case "UPDATE_USER_SUCCESS":{

        var tempArr=state.users.map((item)=>{
          if(item.userName==action.payload.userIdentifier){
            var newData=update(item,{$merge:action.payload.data});

            return newData
          }else{
            var newData=item;

            return newData
          }
        })
        return {
          ...state,
          addUserStatus:1,
          isUpdated:true,
          users:tempArr,
          selectedDataForUpdatedPayment:action.payload.data
        }
      }
      case "ADD_USER_FAILURE":{
        return {
          ...state,
          addUserStatus:-1
        }
      }
      case "UPDATE_FINISHED":{
        return {
          ...state,
          isUpdated:false
        }
      }
      case "UPDATE_DEVICEIPS_ADMIN":{
        return {
          ...state,
          updateDeviceIPStatus:0
        }
      }

      case "UPDATE_DEVICEIPS_ADMIN_SUCCESS":{

        var tempArr=state.devices.map((item)=>{
          if(item.deviceIMEI==action.payload.deviceIMEI){
            var newData=update(item,{$merge:action.payload});

            return newData
          }else{
            var newData=item;

            return newData
          }
        })
        return {
          ...state,
          updateDeviceIPStatus:1,
          isUpdated:true,
          devices:tempArr
        }
      }

      case "UPDATE_SOFTWARE_VERSION":{
        return {
          ...state,
          updateSoftwareVersionStatus:0
        }
      }

      case "UPDATE_SOFTWARE_VERSION_SUCCESS":{

        var tempArr=state.softwareversions.map((item)=>{
          if(item._id==action.payload._id){
            var newData=update(item,{$merge:action.payload});

            return newData
          }else{
            var newData=item;

            return newData
          }
        })
        return {
          ...state,
          updateSoftwareVersionStatus:1,
          isUpdated:true,
          softwareversions:tempArr
        }
      }


      case "FETCH_S3LOGFILENAMES":
        return {
          ...state,
          s3LogFilesFetched: false,
          s3LogFilesFetching: true
        }
  
        case "FETCH_S3LOGFILENAMES_SUCCESS":
      var data=[];
        if(action.payload.length>0){
          
         data= action.payload
        }else {
          data=[]
        }
        return {
          ...state,
          s3LogFilesFetched: true,
          s3LogFilesFetching: false,
          s3LogFiles:data
        }

        case "CLEAR_TWITTERINTA_PLAYLIST":
          return {
            ...state,
            selectedTwitterPlaylist:null,
            selectedInstagramPlaylist:null,
            saveTransaction:false
          }

        case "SAVE_PLAYLIST_NAME":
          return {
            ...state,
            savingPlaylist:true,
            addPlaylistNameStatus:0
          }
          case "SAVE_PLAYLIST_NAME_SUCCESS":
            var newData = action.payload;
            return {
              ...state,
              savingPlaylist:false,
              addPlaylistNameStatus:1,
              selectedPlaylist:newData,
              playlists:[...state.playlists,newData]
            }
          case "DELETE_PLAYLIST":
            return {
              ...state,
              deletedPlaylist:false
            }
          case "DELETE_PLAYLIST_SUCCESS":
            var newData = action.payload;
            return {
              ...state,
              deletedPlaylist:true,
              playlists:state.playlists.filter((item,i)=>(item.playlistName!=action.payload.playlistName))
            }            
            case "GET_PLAYLISTS":
              return {
                ...state,
                playlistsFetching:true,
                playlistsFetched:false,
                savedPlaylist:false,
              }
              case "GET_PLAYLISTS_SUCCESS":
                  var data=[];
                  if(action.payload.length>0){
                    
                   data= action.payload
                  }else {
                    data=[]
                  }
                return {
                  ...state,
                  playlistsFetching:false,
                  playlistsFetched:true,
                  playlists:data
                }

        case "UPDATE_PLAYLIST":
          return {
            ...state,
            savingPlaylist:true,
            savedPlaylist:false
          }
          case "UPDATE_PLAYLIST_SUCCESS":
            return {
              ...state,
              savingPlaylist:false,
              savedPlaylist:true,
              selectedPlaylist:action.payload
            }
            case "UPDATE_TWITTER_PLAYLIST_SUCCESS":
              return {
                ...state,
                savingPlaylist:false,
                savedPlaylist:true,
                selectedTwitterPlaylist:action.payload
              }
              case "UPDATE_INSTAGRAM_PLAYLIST_SUCCESS":
                return {
                  ...state,
                  savingPlaylist:false,
                  savedPlaylist:true,
                  selectedInstagramPlaylist:action.payload
                }
            

          case "GENERATE_GRAPH":
            return {
              ...state,
              generatingGraph:true,
              graphGenerated:false
            }
            case "GENERATE_GRAPH_SUCCESS":
              var newData = action.payload;
              return {
                ...state,
                generatingGraph:false,
                graphGenerated:true,
                isUpdated:true,
                //salesUploadData:
                salesUploadData:[newData,...state.salesUploadData]
              }
              
          case "GET_SALES_UPLOAD_DATA":
            return {
              ...state,
              salesUploadDataFetching:true,
              salesUploadDataFetched:false
            }
            case "GET_SALES_UPLOAD_DATA_SUCCESS":
                var data=[];
                if(action.payload.length>0){
                  console.log("action.payload="+action.payload[0].excelName)
                  data= action.payload
                }else {
                  data=[]
                }
              return {
                ...state,
                salesUploadDataFetching:false,
                salesUploadDataFetched:true,
                salesUploadData:data,
                message:false
              } 
              
              case "GET_EXCLUDE_ADS_FILE":
                return {
                  ...state,
                  gettingExcludeAdsFile:true,
                  gotExcludeAdsFile:false
                }
                case "GET_EXCLUDE_ADS_FILE_SUCCESS":
                  return {
                    ...state,
                    gettingExcludeAdsFile:false,
                    gotExcludeAdsFile:true
                  } 
                case "GET_MANAGER_FOR_USER_SUCCESS":
                  return {
                    ...state,
                    gotManagerForUser:true,
                    managerID:action.payload
                  }
                case "GET_USERS_FOR_ACCESS":
                  return {
                    ...state,
                    gotUsersForAccess:true,
                    users:action.payload
                  } 
                  case "UPDATE_PERMISSIONS":
                    return {
                      ...state
                    }

                    case "FETCH_ROLES" : 
                    return{
                      ...state,
                      //isUpdated:true,
                      rolesFetched : false
                    }
                  
                    case "FETCH_ROLES_SUCCESS":
                      console.log("Roles fetched!!!")
                      return {
                        ...state,
                        roles : action.payload,
                        rolesFetched : true
                      }

                  case "ADD_ROLE":{
                    return {
                      ...state,
                      addRoleStatus:0
                    }
                  }
                  case "ADD_ROLE_SUCCESS":{
console.log("Setting state for success")
                    return {
                      ...state,
                      //isUpdated:true,
                      addRoleStatus:1,
                      roles:[...state.roles,{roleName:action.payload.data.roleName,description:action.payload.data.description,permissions:action.payload.data.permissions}]
                    }
                  }   
                  case "ADD_ROLE_FAILURE":{
                    console.log("Setting state for failure")
                    return {
                      ...state,
                      addRoleStatus:-1
                    }
                  }  

                  case "UPDATE_ROLE":{
                    return {
                      ...state,
                      addRoleStatus:0
                    }
                  }
                  case "UPDATE_ROLE_SUCCESS":{
                  console.log("Setting state for success")
                  console.log("in update action.roleNameOriginal="+action.roleNameOriginal)
                  var newRoles = state.roles.map((role)=>{
                    if(role.roleName==action.roleNameOriginal){
                      return {roleName:action.payload.data.roleName,description:action.payload.data.description,permissions:action.payload.data.permissions}
                    }else{
                      return role;
                    }
                  })
                    return {
                      ...state,
                      //isUpdated:true,
                      addRoleStatus:1,
                      roles:newRoles
                    }
                  }   
                  case "UPDATE_ROLE_FAILURE":{
                    console.log("Setting state for failure")
                    return {
                      ...state,
                      addRoleStatus:-1
                    }
                  }  
                  case "UPDATE_PERMISSIONS_FOR_ROLE":
                    console.log("action.payload="+JSON.stringify(action.payload))
                    var updatedRoles = state.roles.map((role)=>{
                      if(role.roleName==action.payload.roleName){
                        return action.payload;
                      }
                      return role;
                    })
                    console.log("updatedRoles="+JSON.stringify(updatedRoles))
                    return {
                      ...state,
                      roles:updatedRoles
                    }
                  case "SAVE_DESIGN_NAME":
                    return {
                      ...state,
                      savingDesign:true,
                      addDesignNameStatus:0
                    }
                  case "SAVE_DESIGN_NAME_SUCCESS":
                    var newData = action.payload;
                    return {
                      ...state,
                      savingDesign:false,
                      addDesignNameStatus:1,
                      designs:[...state.designs,newData]
                  }     
                  case "GET_DESIGNS":
                    return {
                      ...state,
                      designsFetching:true,
                      designsFetched:false,
                      addDesignNameStatus:-1,
					  addDesignLayout:-1,
                    }
                    case "GET_DESIGNS_SUCCESS":
                      var data=[];
                      if(action.payload.data.length>0){
                        
                        data= action.payload.data
                      }else {
                        data=[]
                      }
                    return {
                      ...state,
                      designsFetching:false,
                      designsFetched:true,
                      designs:data,
                      layouts:data,
                      viewPortrait: false,
                      operation:"fetch",
                      selectedDesignId:action.payload.selectedDesignId
                    } 
                    case "UPDATE_DESIGN":
                      return {
                        ...state,
                        savingDesign:true,
                        addDesignNameStatus:0
                      }
                    case "UPDATE_DESIGN_SUCCESS":
                      var newData = action.payload.data;
                      console.log("****action.payload.type="+action.payload.type)
                      return {
                        ...state,
                        savingDesign:false,
                        addDesignNameStatus:1,
                        designs:
                        action.payload.type=="new"?
                        [...state.designs,newData]:
                        state.designs.map((obj)=>{
                          if(obj._id==newData._id){
                            return newData;
                          }else{
                            return obj;
                          }
                        })
                    }
                    case "DELETE_DESIGN":
                      return {
                        ...state,
                        deletedDesign:false
                      }
                    case "DELETE_DESIGN_SUCCESS":
                      var newData = action.payload;
                      return {
                        ...state,
                        deletedDesign:true,
                        designs:state.designs.filter((item,i)=>(item.designName!=action.payload.designName))
                      }
                      case "GET_APPVERSION":
                      return {
                        ...state,
                        appVersionFetched:false,
                        appVersion:"",
                      }                            
                      case "GET_APPVERSION_SUCCESS":
                        console.log("****app version set to "+action.payload)
                      return {
                        ...state,
                        appVersionFetched:true,
                        appVersion:action.payload,
                      }  
                      case "GET_MONITORVERSION":
                      return {
                        ...state,
                        monitorVersionFetched:false,
                        monitorVersion:"",
                      }                            
                      case "GET_MONITORVERSION_SUCCESS":
                        console.log("****app version set to "+action.payload)
                      return {
                        ...state,
                        monitorVersionFetched:true,
                        monitorVersion:action.payload,
                      }  
                      case "UPDATE_PAGESVISITED":
                        return{
                          ...state,
                        }   
                        case "UPDATE_PAGESVISITED_SUCCESS":
                          var newData = action.payload;
                          return{
                            ...state,                            
                          }                                                        
case "FETCH_CURRENCIES":
                        return {
                          ...state,
                          currencies:action.payload
                        }  
                        case "FETCH_CURRENCIES_CUSTOMER":
                        return {
                          ...state,
                          currenciesForCustomer:action.payload
                        }  
                        case "FETCH_MY_PLAN":
                          console.log("***myPlan="+JSON.stringify(action.payload));
                          return {
                            ...state,
                            myPlan : action.payload && action.payload.length>0?action.payload[0]:{}
                          }  
                          case "DELETE_LICENSES":
                          return{
                            ...state, 
                            addUserStatus:0,                          
                          } 
                          case "DELETE_LICENSES_SUCCESS":
                            console.log("to set delete license status to 1");
                            var tempArr=state.users.map((item)=>{
                              if(item._id==action.payload.data.userID){
                                console.log("updating for user "+item.userName)
                                return {...item,deletelicenses:action.payload.data}
                              }else{
                                return item
                              }
                            })
                          return{
                            ...state, 
                            users:tempArr,
                            addUserStatus:1,                          
                          }      
                          case "DOWNGRADE_LICENSES_SUCCESS":
                            console.log("to set downgrade license status to 1");
                            
                          return{
                            ...state, 
                            addUserStatus:1,                          
                          }    
                          case "SAVE_DESIGN_LAYOUT":                           
                          return{
                            ...state, 
                            addDesignLayout:-1,
                            designsFetched:false
                          }  
                          case "SAVE_DESIGN_LAYOUT_SUCCESS":                           
                          return{
                            ...state, 
                            addDesignLayout:1,
                            designsFetched:true,
                            viewPortrait:action.payload.viewPortrait,
                            operation:"add",
                            selectedDesignId: action.payload.data._id,                       
                            layouts: state.layouts.length==0?
                            [action.payload.data]
                            :[...state.layouts,action.payload.data]
                      }                                                                                                 
                      case "UPDATE_DESIGN_LAYOUT_SUCCESS":
                        console.log("viewPortrait in reducer="+action.payload.viewPortrait)
                        return{
                          ...state,
                          viewPortrait:action.payload.viewPortrait,
                          addDesignLayout:1,
                            designsFetched:true,
                            operation:"update",
                            layouts:state.layouts.map((obj)=>{
                          if(obj._id==action.payload.data._id){
                            return action.payload.data;
                          }else{
                            return obj;
                          }
                        })
                        }
                        case "CHANGE_DESIGN_LAYOUT_NAME_SUCCESS":
                        return{
                          ...state,
                          addDesignLayout:1,                          
                            designsFetched:true,
                            operation:"changeName",
                            viewPortrait:action.payload.viewPortrait,
                            selectedDesignId:action.payload.data._id,
                            layouts:state.layouts.map((obj)=>{
                          if(obj._id==action.payload.data._id){
                            return action.payload.data;
                          }else{
                            return obj;
                          }
                        })
                        }

                        case "DELETE_SELECTED_LAYOUT_SUCCESS":
                        console.log("Design name deleted="+action.payload.data.designName)
                        return {
                          ...state,
                          addDesignLayout:1,                          
                          designsFetched:true,
                          operation:"deleteDesign",
                          viewPortrait:action.payload.viewPortrait,
                          layouts:state.layouts.filter((item,i)=>(item.designName!=action.payload.data.designName))
                        }  

                        case "GET_APPROVERS_SUCCESS":
                        var data=[];
                          if(Object.keys(action.payload).length>0){
                            
                          data= action.payload
                          }else {
                            data={}
                          }
                          console.log("setting approvers to "+JSON.stringify(data))
                          return {
                            ...state,   
                            approvers:data
                          }
                          case "SAVE_TRANSACTION":
                            return{
                              ...state,
                              saveTransaction:false
                            }
                            case "SAVE_TRANSACTION_SUCCESS":
                              return{
                                ...state,
                                saveTransaction:true
                              }    

                              case "GET_CONTENT_APPROVALS":
                                return{
                                  ...state,
                                  contentApprovalsFetched:false,
                                }
                              
                              case "GET_CONTENT_APPROVALS_SUCCESS":
                                return{
                                  ...state,
                                  contentApprovalsFetched:true,
                                  contentApprovals:action.payload
                                }
                                case "GET_AD_DETAILS":
                                return{
                                  ...state,
                                  adForApprovalFetched:false,
                                  adForApproval:null,
                                  contentApprovalsFetched:false
                                }
                              case "GET_AD_DETAILS_SUCCESS":
                                return{
                                  ...state,
                                  adForApprovalFetched:true,
                                  adForApproval:action.payload
                                }

                                case "CLEAR_TRANSACTION":
                                  return{
                                    ...state,
                                    saveTransaction:false
                                  }
                                case "GET_PLAYLISTS_FOR_APPROVAL":
                                  return{
                                    ...state,
                                    playlistsForApprovalFetched:false
                                  }

                                case "GET_PLAYLISTS_FOR_APPROVAL_SUCCESS":
                                  console.log("**reducer playlists"+action.payload)
                                  return{
                                    ...state,
                                    playlistsForApprovalFetched:true,
                                    playlistsForApproval:action.payload
                                  }

                                  case "DISCARD_PLAYLIST_SUCCESS":
                                    console.log("Playlists in discard="+JSON.stringify(state.playlists))
                                    var newData = action.payload;
                                    return {
                                      ...state,
                                      deletedPlaylist:true,
                                      playlistsForApproval:state.playlistsForApproval.filter((item,i)=>(item._id!=action.payload))
                                    }    
                                    
                              case "GET_PLAYLIST_APPROVALS":
                                return{
                                  ...state,
                                  playlistApprovalsFetched:false,
                                }
                              
                              case "GET_PLAYLIST_APPROVALS_SUCCESS":
                                return{
                                  ...state,
                                  playlistApprovalsFetched:true,
                                  playlistApprovals:action.payload
                                }

                                case "GET_PLAYLIST_DETAILS":
                                return{
                                  ...state,
                                  playlistForApprovalFetched:false,
                                  playlistForApproval:null,
                                  playlistApprovalsFetched:false
                                }
                              case "GET_PLAYLIST_DETAILS_SUCCESS":
                                return{
                                  ...state,
                                  playlistForApprovalFetched:true,
                                  playlistForApproval:action.payload
                                }

                                case "SAVE_PLAYLIST_APPROVAL_NAME":
                                return {
                                  ...state,
                                  savingPlaylist:true,
                                  deletedPlaylist:false,
                                  addPlaylistNameStatus:0
                                }
                                case "SAVE_PLAYLIST_APPROVAL_NAME_SUCCESS":
                                  var newData = action.payload;
                                  return {
                                    ...state,
                                    savingPlaylist:false,
                                    addPlaylistNameStatus:1,
                                    selectedPlaylist:newData,
                                    playlistsForApproval:[...state.playlistsForApproval,newData]
                                  }
                                  case "UPDATE_PLAYLIST_APPROVAL":
                                    return {
                                      ...state,
                                      //savingPlaylist:true,
                                      playlistsForApprovalFetched:false,
                                      deletedPlaylist:false,
                                      //savedPlaylist:false
                                    }
                                    case "UPDATE_PLAYLIST_APPROVAL_SUCCESS":
                                      console.log("Playlists here="+JSON.stringify(state.playlists))
                                      var updated = false;
                                      var tempArr=state.playlistsForApproval.map((item)=>{
                                        if(item._id==action.payload._id){
                                          console.log("updating for playlist "+item._id)
                                          var newData=update(item,{$merge:action.payload});
                                          updated = true;
                                          return newData
                                        }else{
                                          var newData=item;
                              
                                          return newData
                                        }
                                      });
                                      if(!updated){
                                        tempArr.push(action.payload);
                                      }
                                      return {
                                        ...state,
                                        // savingPlaylist:false,
                                        // savedPlaylist:true,
                                        addPlaylistNameStatus:1,
                                        playlistsForApprovalFetched:true,
                                        playlistsForApproval:tempArr
                                      }   
                                      
                              case "GET_SCHEDULE_APPROVALS":
                                return{
                                  ...state,
                                  scheduleApprovalsFetched:false,
                                }
                              
                              case "GET_SCHEDULE_APPROVALS_SUCCESS":
                                return{
                                  ...state,
                                  scheduleApprovalsFetched:true,
                                  scheduleApprovals:action.payload
                                }

                                case "GET_SCHEDULE_DETAILS":
                                return{
                                  ...state,
                                  scheduleForApprovalFetched:false,
                                  scheduleForApproval:null,
                                  scheduleApprovalsFetched:false
                                }
                              case "GET_SCHEDULE_DETAILS_SUCCESS":
                                return{
                                  ...state,
                                  scheduleForApprovalFetched:true,
                                  scheduleForApproval:action.payload
                                }
                              case "FETCH_ONLINE_USER_DETAILS":
                                return{
                                  ...state,
                                  onlineUserDetails:action.payload
                                }
                                
  }
  return state;
}
