import React, { Component } from "react";
import Switch from "react-switch";
import {getCustomerDetails,updateStripeOperationCompleted,saveSubscPeriod} from "../../redux/actions/authAction.js"
import {  deleteLicenses, getSubscriptionRatesForCurrency } from "../../redux/actions/userAdminAction"
import Navbar from "../navbars/NavbarBootAll"
import {connect} from "react-redux";
import moment from "moment";
import axios from "../../utils/axiosInstance"

import Delete_Licenses from "../admin/Delete_Licenses.js";

// import ToggleSwitch from "../../components/toggleSwitch.js"; 
import "../../../css/formcomponent/input.css";
import "../../../css/planPage.css";
import {loadStripe} from '@stripe/stripe-js';

@connect((store)=>{
  return {
    customerDetails:store.auth.customerDetails,
    currenciesForCustomer:store.usersAdmin.currenciesForCustomer,
    previousSubscriptionPeriod:store.auth.previousSubscriptionPeriod
  }
})
export default class MyPlan extends React.Component {
  constructor(props){
super(props);
this.state={
  licenseType:"",
  subscriptionPeriod:'Monthly',//0-Monthly,1-Yearly
  currency:"INR",
  monthlyLite:0,
  monthlyPro:0,
  yearlyLite:0,
  yearlyPro:0,
  monthlyLitePlan:"",
  monthlyProPlan:"",
  yearlyLitePlan:"",
  yearlyProPlan:"",
  stripeCustomerID:"",
  openCustomerPortal:false,
  billingValue:0,
  approvedLicenses:0,
  subscriptionPeriodToDisplay:"",
  startDate:null,
  expiryDate:null,
  daysToExpire:0,
  stripeOperationCompleted:"",
  openDeleteLicenseModal:false,
  customerDetails:{},
  customerType:"",
}
}

  
  setFieldValue=({target})=>{
     this.setState({[target.name]:target.value})
   }

   componentDidMount(){	
    var daysToExpire = JSON.parse(localStorage.getItem("userObj")).daysToExpire;    
    this.setState({daysToExpire});
    this.props.dispatch(getCustomerDetails(JSON.parse(localStorage.getItem("userObj")).userID));    
   }

   componentWillReceiveProps(nextProps){
    console.log("HERE!!"+nextProps.subscriptionPeriod);
	var daysToExpire = JSON.parse(localStorage.getItem("userObj")).daysToExpire;    
    this.setState({daysToExpire});
	console.log("***daysToExpire="+daysToExpire)
    if(nextProps.customerDetails){
		console.log("***currenciesForCustomer length="+nextProps.currenciesForCustomer.length)
		if(nextProps.currenciesForCustomer && nextProps.currenciesForCustomer.length>0){
			console.log("***currenciesForCustomer="+JSON.stringify(nextProps.currenciesForCustomer));
			var monthlyLite,monthlyPro,yearlyLite,yearlyPro=0;
			var monthlyLitePlan,monthlyProPlan,yearlyLitePlan,yearlyProPlan="";
			nextProps.currenciesForCustomer.map((obj)=>{
			  if(obj.subscriptionPeriod=="Monthly"){
				if(obj.licenseType=="Lite"){
				  monthlyLite = obj.pricing;
				  monthlyLitePlan = obj.stripePriceID;
				}
				if(obj.licenseType=="Pro"){
				  monthlyPro = obj.pricing;
				  monthlyProPlan = obj.stripePriceID;
				}
			  }
			  if(obj.subscriptionPeriod=="Annually"){
				if(obj.licenseType=="Lite"){
				  yearlyLite = obj.pricing;
				  yearlyLitePlan = obj.stripePriceID;
				}
				if(obj.licenseType=="Pro"){
				  yearlyPro = obj.pricing;
				  yearlyProPlan = obj.stripePriceID;
				}
			  }
			});
			this.setState({monthlyLite,monthlyPro,yearlyLite,yearlyPro,
			  monthlyLitePlan,monthlyProPlan,yearlyLitePlan,yearlyProPlan
			});
		  } else {
			console.log("Customer currency being used="+nextProps.customerDetails.baseCurrency)
			nextProps.dispatch(getSubscriptionRatesForCurrency(nextProps.customerDetails.baseCurrency));
		  }
      this.setState({currency:nextProps.customerDetails.baseCurrency})
		 } 


      console.log("in comp will rece props"+nextProps.customerDetails.stripeCustomerID)
	  //Replace stripeOperationCompleted in localStorage
	  var userObj = JSON.parse(localStorage.getItem("userObj"));
	  userObj.stripeOperationCompleted = nextProps.customerDetails.stripeOperationCompleted;
	  localStorage.setItem("userObj",JSON.stringify(userObj));

      this.setState({customerDetails:{_id:JSON.parse(localStorage.getItem("userObj")).userID,customerDetails:nextProps.customerDetails,
      deletelicenses:nextProps.customerDetails.deletelicenses
      },licenseType:nextProps.customerDetails.licenseType,stripeCustomerID:nextProps.customerDetails.stripeCustomerID,
      billingValue:nextProps.customerDetails.billingValue,approvedLicenses:nextProps.customerDetails.approvedLicenses,
      subscriptionPeriodToDisplay:nextProps.customerDetails.subscriptionPeriod,
      startDate:nextProps.customerDetails.startDate,expiryDate:nextProps.customerDetails.expiryDate,
      stripeOperationCompleted:nextProps.customerDetails.stripeOperationCompleted,customerType:nextProps.customerDetails.customerType});
      if(nextProps.customerDetails.subscriptionPeriod){
        if(nextProps.customerDetails.subscriptionPeriod=='Monthly'){
          this.setState({subscriptionPeriod:'Monthly'});
        }
        if(nextProps.customerDetails.subscriptionPeriod=='Annually'){
          this.setState({subscriptionPeriod:'Annually'});
        }
      }
      if(nextProps.previousSubscriptionPeriod){
        this.setState({subscriptionPeriod:nextProps.previousSubscriptionPeriod})
      }
      if(this.state.openCustomerPortal && nextProps.customerDetails.stripeRedirectURL){
        if(nextProps.customerDetails.stripeCustomerPortalOperation == 'invoicehistory'){
          window.open(nextProps.customerDetails.stripeRedirectURL,'_self');
        }else{
          window.open(nextProps.customerDetails.stripeRedirectURL+"/subscriptions/"+nextProps.customerDetails.stripeSubscriptionID+"/"+nextProps.customerDetails.stripeCustomerPortalOperation,'_self');
        }
        
      }
      var currentUserObj = JSON.parse(localStorage.getItem("userObj"));  
      var previousPermissions = currentUserObj.permissions; 
      currentUserObj.permissions = nextProps.customerDetails.permissions;
      
      console.log("***previousPermissions="+previousPermissions.length)
      console.log("***nextProps.customerDetails.permissions="+nextProps.customerDetails.permissions.length)
      if(previousPermissions.length!==nextProps.customerDetails.permissions.length){
        console.log("permissions not same!");
        localStorage.setItem("userObj",JSON.stringify(currentUserObj));
        window.location.reload();
      }
      if(nextProps.customerDetails.stripeOperationCompleted=='delete'){
        this.setState({openDeleteLicenseModal:true})
      }
    }
    

   closeDeleteLicensesModal=()=>{
    this.setState({openDeleteLicenseModal:false,  selectedDataForDelete:null});
    this.props.dispatch(updateStripeOperationCompleted(JSON.parse(localStorage.getItem("userObj")).userID));
    }

   toggleSwitch = () =>{
    var currentSubscriptionPeriod = this.state.subscriptionPeriod;
    var newSubscriptionPeriod;
    console.log("currentSubscriptionPeriod="+currentSubscriptionPeriod)
    if(currentSubscriptionPeriod=='Monthly'){
      newSubscriptionPeriod = 'Annually';
      
    } else {
      newSubscriptionPeriod = 'Monthly';
    }
    this.setState({subscriptionPeriod:newSubscriptionPeriod})
   }

   openStripePortal = (currency, subscriptionPeriod, licenseType) =>{
    var subscriptionPeriodToPass = subscriptionPeriod;//subscriptionPeriod==0?'Monthly':'Annually';
    var amountToPass = 0;
    var priceId = '';
    //if(currency=='INR'){
      if(licenseType=='Lite'){
        if(subscriptionPeriod=='Monthly'){
          priceId = this.state.monthlyLitePlan;//'price_1P6AeASDpAqJ1BU0DxGNaFA6';
          amountToPass = this.state.monthlyLite;
        }
        if(subscriptionPeriod=='Annually'){
          priceId = this.state.yearlyLitePlan;//'price_1PETKiSDpAqJ1BU0H5CCgq7m';
          amountToPass = this.state.yearlyLite;
        }
      }
      if(licenseType=='Pro'){
        if(subscriptionPeriod=='Monthly'){
          priceId = this.state.monthlyProPlan;//'price_1P6AeXSDpAqJ1BU0L49Bjx3o';
          amountToPass = this.state.monthlyPro;
        }
        if(subscriptionPeriod=='Annually'){
          priceId = this.state.yearlyProPlan;//'price_1PETKCSDpAqJ1BU0K3JJRK1z';
          amountToPass = this.state.yearlyPro;
        }
      }
    //}
    console.log("Redirect to Stripe!");
    axios.post("/api/createCheckoutSession", {
     userID: JSON.parse(localStorage.getItem("userObj")).userID,   
     licenseType,
     subscriptionPeriod:subscriptionPeriodToPass,
     currency,
     amount:amountToPass,
     items: [{ id: priceId }] ,
    }).then((res) => { 
      console.log("***res="+JSON.stringify(res));
      window.open(res.data,"_self")
    }).then((data) => {
      //setClientSecret(data.clientSecret);
    });
  }

  openStripeCustomerPortal = (operation) =>{
    console.log("Redirect to Stripe!");
    axios.post("/api/openStripeCustomerPoral", {
     userID: JSON.parse(localStorage.getItem("userObj")).userID,   
     operation,
     stripeCustomerID:this.state.stripeCustomerID,
    }).then((res) => { 
      console.log("***res="+JSON.stringify(res));
      this.setState({openCustomerPortal:true});
      this.props.dispatch(saveSubscPeriod(this.state.subscriptionPeriod));
      var self = this;
      setTimeout(() => {
        console.log("should come after 5 seconds!!");
        
        self.props.dispatch(getCustomerDetails(JSON.parse(localStorage.getItem("userObj")).userID));        
        
      }, 1000); 
      
      
      //window.open(res.data,"_self")
    }).then((data) => {
      //setClientSecret(data.clientSecret);
    });
  }

  sendMailForEnterprise = () =>{
    console.log("Sending mail for Enterprise!");
    var userName = JSON.parse(localStorage.getItem("userObj")).userName;
    var firstName = JSON.parse(localStorage.getItem("userObj")).firstName;
    var lastName = JSON.parse(localStorage.getItem("userObj")).lastName;
    var emailId = JSON.parse(localStorage.getItem("userObj")).emailId;
    var supportEmailId = 'support@intelisa.in';
    var urlEncodedBody = 'Dear%20Team%2C%0AI%20would%20like%20to%20enquire%20%26%20subscribe%20to%20Intelisa%27s%20Enterprise%20Plan%20for%20my%20account%20'+userName+'.%0APlease%20contact%20me%20to%20assist%20in%20the%20process.%0A%20%0ARegards%2C%0A'+firstName+'%20'+lastName;
    window.open("mailto:"+supportEmailId+"?subject=Subscribe to Intelisa's Enterprise Plan&body="+urlEncodedBody)
    //this.props.dispatch(sendMailForLicense(JSON.parse(localStorage.getItem("userObj")).userName));
    //this.setState({openWarningModal:false});
  }

  sendMailForSwitchFromTrial = ()=>{
    var userName = JSON.parse(localStorage.getItem("userObj")).userName;
    var firstName = JSON.parse(localStorage.getItem("userObj")).firstName;
    var lastName = JSON.parse(localStorage.getItem("userObj")).lastName;
    var emailId = JSON.parse(localStorage.getItem("userObj")).emailId;
    var supportEmailId = 'support@intelisa.in';
    var urlEncodedBody = 'Hi%20Support%20Team%2C%0A%0AI%20would%20like%20to%20subscribe%20to%20Intelisa%27s%20Monthly%2FYearly%20Plan.%20%0APlease%20help.%0A%0ARegards%2C%0A'+userName;
    window.open("mailto:"+supportEmailId+"?subject=Subscribe to Intelisa's Monthly/Yearly Plan&body="+urlEncodedBody)
  }

  sendMailForSupport = () =>{
    console.log("Sending mail for Support!");
    // var userName = JSON.parse(localStorage.getItem("userObj")).userName;
    // var firstName = JSON.parse(localStorage.getItem("userObj")).firstName;
    // var lastName = JSON.parse(localStorage.getItem("userObj")).lastName;
    // var emailId = JSON.parse(localStorage.getItem("userObj")).emailId;
    var supportEmailId = 'support@intelisa.in';
    //var urlEncodedBody = 'Dear%20Team%2C%0AI%20would%20like%20to%20enquire%20%26%20subscribe%20to%20Intelisa%27s%20Enterprise%20Plan%20for%20my%20account%20'+userName+'.%0APlease%20contact%20me%20to%20assist%20in%20the%20process.%0A%20%0ARegards%2C%0A'+firstName+'%20'+lastName;
    window.open("mailto:"+supportEmailId+"?subject=Support")
  }

   
  render() {


    return (
      <div class="h-100 w-100">
      <Navbar />
        <div class="content-wrapper no-scroll" style={{width:"100%"}}>
          <div class="row">
            <div class="col-md-12 ">   


              <div class="price-table-box">

                <div class="price-page-title">
                  <div class="blackTitle">Manage </div><div class="redTitle">&nbsp;Plans and Billing</div> 
                </div>

              {this.state.licenseType=="Trial" || this.state.licenseType=="Pilot"?
              this.state.daysToExpire<=0?
              <div class="messageTop">Your trial has expired on {moment(this.state.expiryDate).format("Do MMM, YYYY")}, pick a plan to get started!</div>
              :
              this.state.customerType=='online'?
              <div class="messageTop">
              
              You are currently on our 14 day Pro-featured trial plan. Subscribe and unlock all the features. 
                </div>
              :
              <div class="messageTop">
              You are currently on our 14 day Pro-featured trial plan. You can <span onClick={()=>this.sendMailForSwitchFromTrial()}  style={{cursor:"pointer",textDecoration:"underLine"}}>contact us</span> to subscribe and unlock all the features.
              </div>
              
              
              :              
              <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
              {this.state.stripeOperationCompleted=='cancel'?
              <div class="messageTop">Your plan will be cancelled on {moment(this.state.expiryDate).format("Do MMM, YYYY")}.</div>
              :this.state.daysToExpire<=0 && this.state.customerType=="online"?
              <div class="messageTop">Your license has expired. Please <button style={{background:"transparent",textDecoration:"underLine",border:"0px solid red",padding:"0px", color:"black",cursor:"pointer",fontWeight:'bold'}}  onClick={()=>this.openStripeCustomerPortal('reactivate')}>renew</button> your license to unlock all the features.</div>
              
              :null}
              {/* {this.state.stripeOperationCompleted=='downgrade'?
              <div class="messageTop">Your plan will be downgraded on {moment(this.state.expiryDate).format("Do MMM, YYYY")}.</div>
              :null} */}
              <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                <table style={{border: "1px solid black", width: "500px", height: "80px",borderRadius:"10px",borderCollapse:'collapse'}}>
                    <tr style={{borderBottom: "1px solid black"}}>
                      <td style={{paddingLeft:"10px"}}>Current Plan</td>
                      <td style={{borderLeft: "1px solid black", paddingLeft:"10px"}}>{this.state.licenseType}&nbsp;{this.state.subscriptionPeriodToDisplay}</td>
                    </tr>
                    {this.state.customerType == "online"?
                    <tr style={{borderBottom: "1px solid black"}}>
                      <td style={{paddingLeft:"10px"}}>Subscription Start Date</td>
                      <td style={{borderLeft: "1px solid black", paddingLeft:"10px"}}>{moment(this.state.startDate).format("Do MMMM, YYYY")}</td>
                    </tr>:null}
                    <tr style={{borderBottom: "1px solid black"}}>
                      <td style={{paddingLeft:"10px"}}>Subscription End Date</td>
                      <td style={{borderLeft: "1px solid black", paddingLeft:"10px"}}>{moment(this.state.expiryDate).format("Do MMMM, YYYY")}</td>
                    </tr>
                   
                    <tr style={{borderBottom: "1px solid black"}}>
                      <td style={{paddingLeft:"10px"}}>No. of licenses</td>
                      <td style={{borderLeft: "1px solid black", paddingLeft:"10px"}}>{this.state.approvedLicenses}</td>
                    </tr>
                    {this.state.licenseType!=='Trial' && this.state.customerType=="online"?
                    <tr style={{borderBottom: "1px solid black"}}>
                      <td style={{paddingLeft:"10px"}}>Billing amount(excluding tax)</td>
                      <td style={{borderLeft: "1px solid black", paddingLeft:"10px",display:"flex",flexDirection:"row"}}>{this.state.currency=="INR"?"₹":"$"}&nbsp;{this.state.billingValue?this.state.billingValue.toFixed(2):""}
                      &nbsp;
                      {this.state.customerType=="online"?
                      <div style={{textDecoration:'underLine',cursor:'pointer',fontSize:"14px"}} onClick={()=>this.openStripeCustomerPortal('invoicehistory')}>View Details & Invoices</div>
                      :null}
                      </td>
                    </tr>:null}
                  </table>
              </div>
              {
              this.state.customerType=="online"?
              this.state.stripeOperationCompleted=='cancel'?
              <div style={{cursor:"pointer",textDecoration:'underLine',paddingTop:"10px"}} onClick={()=>this.openStripeCustomerPortal('reactivate')}>Renew Subscription</div>
              :
			  <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
				<div style={{cursor:"pointer",textDecoration:'underLine',paddingTop:"10px"}} onClick={()=>this.openStripeCustomerPortal('update')}>Update Quantity</div>			  
				<div style={{cursor:"pointer",textDecoration:'underLine',paddingTop:"10px"}} onClick={()=>this.openStripeCustomerPortal('cancel')}>Cancel Subscription</div>
        <div style={{fontSize:"12px"}}>For any subscription or billing related issues, write to us at <span onClick={()=>this.sendMailForSupport()}  style={{cursor:"pointer",textDecoration:"underLine"}}>support@intelisa.in</span></div>
			  </div>              
              :<div style={{fontSize:"12px"}}>For any subscription or billing related issues, write to us at <span onClick={()=>this.sendMailForSupport()}  style={{cursor:"pointer",textDecoration:"underLine"}}>support@intelisa.in</span></div>}
              </div>
              }
                {this.state.customerType=="online"?
                <div class="slideToggle">
                  {/* <label class="form-switch">
                    <span class="beforeinput">
                      Pay Monthly
                    </span>
                    <input type="checkbox" id="js-contcheckbox"/>
                    <i></i>
                    <span class="afterinput">
                      Pay Yearly
                    </span>
                  </label> */}
                  <div style={{display:"flex",flexDirection:"row"}}>
                  <div style={{paddingRight:"10px"}}>Pay Monthly</div>
                  <div><Switch onChange={this.toggleSwitch} checked={this.state.subscriptionPeriod=='Annually'} 
                        onColor="#c1011a" offColor="#c1011a" 
                        uncheckedIcon={false} checkedIcon={false} height={20} width={40}/>
                  </div>
                  <div style={{paddingLeft:"10px"}}>Pay Yearly</div>
                  </div>
                </div>
                :null}
                {this.state.customerType=="online"?
                <div class="pricingtablecontainer" style={{width:"100%",border:"0px solid red"}}>
                  <div class={this.state.licenseType=='Lite' && this.state.subscriptionPeriod==this.state.subscriptionPeriodToDisplay?
                  "pricingtable selectedplan":"pricingtable"} style={{marginTop:this.state.licenseType=='Trial' || this.state.licenseType=='Pilot' ?'30px': this.state.licenseType=='Lite'?'20px':'10px'}}>
                    <div class="silver" style={{paddingTop:this.state.licenseType=='Trial'||this.state.licenseType=='Pilot'?"20px":"0px"}}>
                      <div class="pricingtable__head">Intelise Lite</div>
                      <div class="pricingtable__highlight js-montlypricing"><span class="prices">
                      {this.state.currency=="INR"?"₹":"$"}
                        {this.state.subscriptionPeriod=="Monthly"?
                          this.state.monthlyLite
                        : this.state.yearlyLite
                        }
                      </span> <span>/ screen {this.state.subscriptionPeriod=="Monthly"?'/ month':'/ year'}</span></div>
                     
                        {this.state.licenseType=='Lite' && this.state.subscriptionPeriod==this.state.subscriptionPeriodToDisplay?
                        <div class="price-button-currentplan" onClick={()=>this.openStripeCustomerPortal('update')}  style={{cursor:"pointer"}}>Current Plan <i class="fa fa-check"></i></div>
                        :  this.state.licenseType=='Pro' || (this.state.licenseType=='Lite' && this.state.subscriptionPeriod!==this.state.subscriptionPeriodToDisplay)?
                        <div class="price-button-subscribe" onClick={()=>this.openStripeCustomerPortal('update')}  style={{cursor:"pointer"}}>Switch to Lite {this.state.subscriptionPeriod}</div>
                        :
                        <div class="price-button-subscribe" onClick={()=>this.openStripePortal(this.state.currency,this.state.subscriptionPeriod,'Lite')} style={{cursor:"pointer"}}>Subscribe</div>
                        } 
                    </div>

                    <ul class="price-list"> 
                      <li>Creative Management</li>
                      <li>Integration with Canva</li>
                      <li>Playlist Management</li>
                      <li>Basic Scheduling by time and in advance for 10 Days</li>
                      <li>Offline Mode</li>
                      <li>Screen  Grouping and Tagging</li>
                      <li>Live Previews of every screen</li>
                      <li>Live Dashboarding  for every view</li>
                      <li>Plug and Play Setup</li>
                      <li>Email Support</li> 
                    </ul>
                  </div>

                  {/* <fieldset><legend>Best Value */}
                  {/* <div class={this.state.licenseType=='Trial' || this.state.licenseType=='Pilot'?"pricingtable pro-bg":
                  this.state.licenseType=='Pro' && this.state.subscriptionPeriod==this.state.subscriptionPeriodToDisplay?"pricingtable selectedplan":"pricingtable"}></div> */}
                  <fieldset class={this.state.licenseType=='Trial' || this.state.licenseType=='Pilot'?"pricingtable pro-bg":
                  this.state.licenseType=='Pro' && this.state.subscriptionPeriod==this.state.subscriptionPeriodToDisplay?"pricingtable selectedplan":"pricingtable"}>
                  {this.state.licenseType=='Trial' || this.state.licenseType=='Pilot'?<legend>Best Value</legend>:null}
                    <div class="popular">
                      <div class="pricingtable__head">Intelisa Pro</div>
                      <div class="pricingtable__highlight js-montlypricing"><span class="prices">
                      {this.state.currency=="INR"?"₹":"$"}
                        {this.state.subscriptionPeriod=="Monthly"?
                          this.state.monthlyPro
                        : this.state.yearlyPro
                        } </span> <span>/ screen {this.state.subscriptionPeriod=="Monthly"?'/ month':'/ year'}</span></div>
                      
                        {this.state.licenseType=='Pro' && this.state.subscriptionPeriod==this.state.subscriptionPeriodToDisplay?
                        <div class="price-button-currentplan" onClick={()=>this.openStripeCustomerPortal('update')}  style={{cursor:"pointer"}}>Current Plan <i class="fa fa-check"></i></div>
                        :  this.state.licenseType=='Lite' || (this.state.licenseType=='Pro' && this.state.subscriptionPeriod!==this.state.subscriptionPeriodToDisplay)?
                        <div class="price-button-subscribe" onClick={()=>this.openStripeCustomerPortal('update')}  style={{cursor:"pointer"}}>Switch to Pro {this.state.subscriptionPeriod}</div>
                        :
                        <div class="price-button-subscribe" onClick={()=>this.openStripePortal(this.state.currency,this.state.subscriptionPeriod,'Pro')}  style={{cursor:"pointer"}}>Subscribe</div>
                        }
                      <ul class="pro"> 
                        <li>All features of Intelisa Lite</li>
                        <li>User Access Management</li>
                        <li>Scheduling for 3 Months in advance</li>
                        <li>Plays Offline</li>
                        <li>Social Media Integration (Twitter Instagram)</li>
                        <li>Split Screen</li>
                        <li>Advance Reporting</li>
                        <li>Live Streaming</li>
                        <li>YouTube Videos</li>
                        <li>Widgets</li>
                        <li>Onboarding Service with Account Executive</li>
                      </ul>
                    </div>                    
                  {/* </div> */}
                  
                    </fieldset>

                  <div class="pricingtable"  style={{marginTop:this.state.licenseType=='Trial' || this.state.licenseType=='Pilot'?'30px':this.state.licenseType=='Lite'?'15px':'10px'}}>
                    <div class="enterprise" style={{paddingTop:this.state.licenseType=='Trial'||this.state.licenseType=='Pilot'?"20px":"0px"}}>
                      <div class="pricingtable__head">For Enterprise</div>
                      <div class="pricingtable__highlight js-montlypricing"> </div>
                      <div class="pricingtable__highlight js-yearlypricing"> </div> 
                      {/* <div style={{height:"5px"}}>&nbsp; </div>  */}
                      <div class="price-button-subscribe" onClick={()=>this.sendMailForEnterprise()}  style={{cursor:"pointer"}}>Contact Us</div>

                    </div>
                    <ul class="diamond"> 
                      <li>All features of <span>Intelisa Pro</span></li>
                      <li>Advanced and Customized features tailored for sectors: Retail, Hospitals and Wayfinding</li>
   
                    </ul>
                  </div>

                </div>
                :null}
              </div>
            </div>  
          </div>
        </div>
        {this.state.openDeleteLicenseModal? <Delete_Licenses  selectedDataForDelete={this.state.customerDetails} source="MYPLAN" closeHandler={()=>this.closeDeleteLicensesModal()} />:null}
      </div>
    );
  }
}

