import React from "react";
import {login, registerUser} from "../../redux/actions/authAction.js"
import {connect} from "react-redux";
import Input from "../../components/formcomponent/Input"
import Login_Form from "../../components/Auth/Login_Form"
import Reset_Form from "../../components/Auth/Reset_Form"
import My_Plan from "../../components/Auth/myplan.js";
import Register_Form from "../../components/Auth/Register_Form"
import Verification_Form from "../../components/Auth/verification.js"
import Email_Form from "../../components/Auth/Email_Form.js";

import { Redirect } from 'react-router-dom'
@connect((store)=>{
  return {
    isAuthenticated:store.auth.isAuthenticated,
    resetPWD:store.auth.resetPWD
  }
})
export default class Login extends React.Component {
  constructor(props){
super(props);
this.state={
  componentID:'L_1'

}
  }

  componentWillReceiveProps(nextProps){
    console.log("In Login componentID-"+this.state.componentID)
    
  }



   getRedirectPath() {
    console.log("***In getredirectpath="+JSON.parse(localStorage.getItem("userObj")).userID)
    const locationState = this.props.location.state
    if (locationState && locationState.from.pathname) {
      return locationState.from.pathname // redirects to referring url
    } else {
      return '/'
    }
  }

  setComponentId = (componentID) =>{
    this.setState({componentID})
  }


  render() {
    if(this.props.isAuthenticated && !this.props.resetPWD 
      //&& JSON.parse(localStorage.getItem("userObj")).userID!=="61aa085d36cec059cb4c12df"
      ){
      return(
      <Redirect to={{
       pathname: this.getRedirectPath(), state: {
         from: this.props.location
       }
     }}/>)

    }
    // let { from } = this.props.location.state || { from: { pathname: "/" } };
    // let { redirectToReferrer } = this.state;
    
    // if (redirectToReferrer) return <Redirect to={from} />;

    return (
      <div class="login-page d-flex align-items-center flex-column justify-content-center h-100">
      {/* {this.state.componentID=="L_1"?(!this.props.resetPWD?<Login_Form setComponentId={(componentID)=>this.setComponentId(componentID)}/>:<Reset_Form />):<Register_Form setComponentId={(componentID)=>this.setComponentId(componentID)} />} */}
    {/* {this.state.componentID=="L_1"?<a class="pointer d-none " onClick={()=>this.setState({componentID:'R_2'})} >Register</a>:<a class="pointer" onClick={()=>this.setState({componentID:'L_1'})} >Already a member? Sign in.</a>} */}

    {/* {this.state.componentID=="L_1"?(this.props.resetPWD?<Login_Form setComponentId={(componentID)=>this.setComponentId(componentID)}/>:<My_Plan />): */}
    {this.state.componentID=="L_1"?(!this.props.resetPWD?<Login_Form setComponentId={(componentID)=>this.setComponentId(componentID)} source="Login"/>:<Reset_Form />):
    (this.state.componentID=="L_3"?<Verification_Form setComponentId={(componentID)=>this.setComponentId(componentID)}/>:
    (this.state.componentID=="R_5"?<Email_Form setComponentId={(componentID)=>this.setComponentId(componentID)}/>:
    (this.state.componentID=="R_3"?<Reset_Form/>:<Register_Form setComponentId={(componentID)=>this.setComponentId(componentID)}/>)))
    
    }
    
</div>
    );
  }
}
