import React from "react";
import {sendEmailForRegister, registerUser,login} from "../../redux/actions/authAction.js"
import ActionButton from "../formcomponent/ActionButton"
import {connect} from "react-redux";
import Input from "../formcomponent/Input";
import Input1 from "../formcomponent/Input1";
import { Redirect } from 'react-router-dom'
import Login_Form from "./Login_Form.js";
import i18n from '../../i18n';
import {  Trans } from 'react-i18next';
import detectBrowserLanguage from 'detect-browser-language'
import { locale } from "moment";
const jwt = require('jsonwebtoken');
import axios from "../../utils/axiosInstance";
// var randomize = require('randomatic');
import "../../../css/formcomponent/input.css"
import ComponentLoaderSmall from "../uilib/ComponentLoaderSmall.js";

@connect((store)=>{
  return {
    isAuthenticating:store.auth.isAuthenticating,
    isAuthenticated:store.auth.isAuthenticated,
    initialState:store.auth.initialState,
  }
})
export default class Register_Form extends React.Component {
  constructor(props){
  super(props);
  this.state={
      firstName:'',
      lastName:'',
      password:"",
      pwd:"",
      emailId:"",
      registerRequest:false,
      role : "001",
      token:"",
      isRegisterError:false,
      emptyPassword:false,
      passwordLength:false,
      passwordMismatch:false,
      emptyFirstname:false,
      showPassword:false,
      showPassword1:false,
      loading:false

    }
  }

  componentDidMount(){
    // console.log("in register language change", localStorage.getItem("locale"))
    if(localStorage.getItem("locale") == "fr"){
      localStorage.setItem("locale","fr");
    i18n.changeLanguage("fr");  
  
    this.setState({lang:"fr"})
    }
    else{
      
      localStorage.setItem("locale","en");
      i18n.changeLanguage("en");  
  
      this.setState({lang:"en"})}
    
  }

  handleChange=(e)=>{
    // console.log("Change Language!!!!")
    if(e.target.value == "English"){
      //set "EN"
      // console.log("in handle lang="+(e.target.value))
      localStorage.setItem("locale","en");
      i18n.changeLanguage("en");  
    
      this.setState({lang:"en"})
    }
    else if(e.target.value == "Francais"){
      //set "FR"
      // console.log("in handle lang="+(e.target.value))
      localStorage.setItem("locale","fr");
      i18n.changeLanguage("fr");  
      
      this.setState({lang:"fr"})
    }
    
  }

  handleClickShowPassword = () => {
    // console.log("inhandle click show password", this.state.showPassword)
    var currShowPass = this.state.showPassword
    // console.log("value of click show password", currShowPass)
    this.setState({showPassword:!currShowPass})
  };

  eyeHandler = () => {
    var imglink = " ";
    if(this.state.showPassword){imglink = "images/eye_open.png"}
    else{imglink = "images/eye_close.png"}
    return imglink
  };
  
  handleClickShowPassword1 = () => {
    var currShowPass1 = this.state.showPassword1
    this.setState({showPassword1:!currShowPass1})
  };

  eyeHandler1 = () => {
    var imglink1 = " ";
    if(this.state.showPassword1){imglink1 = "images/eye_open.png"}
    else{imglink1 = "images/eye_close.png"}
    return imglink1
  };

  register = () => {
    // console.log("All Good In sending Mail")
    this.setState({registerRequest:false})
    this.setState({emptyPassword:false})
    this.setState({emptyFirstname:false})
    this.setState({passwordMismatch:false})
    this.setState({passwordLength:false})
    if(this.state.firstName && this.state.emailId){

       if((this.state.password === this.state.pwd) && (this.state.pwd.length > 5)){
          // this.state.token = tempToken
          // this.props.dispatch(registerUser(this.state))
          // console.log("Registering!!!!", this.state.registerRequest)


          // this.props.dispatch(sendEmailForRegister(this.state));
          // this.setState({registerRequest:true})
          // // this.state.registerRequest = true
          // // console.log("Registering!!!!", this.state.registerRequest)
          // this.props.setComponentId('L_3');
          var user = this.state;
          this.setState({loading:true,isRegisterError:false,emptyPassword:false,passwordLength:false,passwordMismatch:false});
           axios.post(`/api/register`,{user}).then((response)=>{//sendRegisterMail
            console.log("****response="+response.data)
            // dispatch({type:"SENDMAIL_RESET_SUCCESS"})
            this.setState({registerRequest:true})
            this.props.setComponentId('L_3');
          }).catch((err)=>{
            console.log("err="+err)
            // dispatch({type:"SENDMAIL_RESET_FAILED"})
            this.setState({isRegisterError:true,emptyPassword:false,passwordLength:false,passwordMismatch:false,loading:false})
          })
        }else {
          if((this.state.password === "") || (this.state.pwd === "")){
            this.setState({emptyPassword:true,isRegisterError:false,passwordLength:false,passwordMismatch:false,loading:false})
          }
          else if(((this.state.pwd.length < 6) || (this.state.password.length < 6)) && ((this.state.password !== "") || (this.state.pwd !== ""))){
            this.setState({passwordLength:true,emptyPassword:false,isRegisterError:false,passwordMismatch:false,loading:false})
          }
          else if(this.state.password !== this.state.pwd){
            this.setState({passwordMismatch:true,passwordLength:false,emptyPassword:false,isRegisterError:false,loading:false})
          }
          
          
          console.log("Data Error !!!!")
          // this.setState({wrongPassword:true})
        }
    }else {
      console.log("Data Error !!!!")
      this.setState({emptyFirstname:true})
    }
  };
  setFieldValue=({target})=>{

     this.setState({[target.name]:target.value})

   }


  loginUser = () =>{
    this.props.setComponentId('L_1');
  }

  render() {

    return (
      <div>
        
        <div style={{height:"100vh",width:"100vw",display:"flex",flexDirection:"row",overflowY:"auto",overflowX:"auto",background:"white",fontFamily:"Inter",columnCount:"2"}}>

        <div class="d-flex align-items-center justify-content-left" style={{width:"47%", position: 'relative'}}>
            <img   src="images/background.png" height="100%" width="100%"  alt="Card image cap" />
            <div style={{position: 'absolute', top: "40%", left: "32%", pointerEvents: 'none'}}>
              <img   src="images/Logo.svg" alt="SVG Overlay" height="100%"  width="100%"/>
            </div>
          </div>

          <div class="login-form justify-content-right" style={{height:"100%",width:"50%",background:"transparent",display:"flex",flexDirection:"column",padding: "0"}}>
            
            <div style={{height:"10%",width:"100%",textAlign:"right",color:"black",fontFamily:"Inter"}}>
              {/*<br/><Trans i18nKey="login.selectlang"/>&nbsp;&nbsp;
              <select 
                value={this.state.selectValue} 
                onChange={this.handleChange} 
              >
              <option value="English">English</option>
                <option value="Francais">FranÃ§ais</option>
               
              </select>*/}
               
            </div>

            <div class="login-form d-flex no-shadow" style={{border:"0px solid black",height:"8%",width:"100%",textAlign:"center",color:"black",fontWeight: "bold",fontFamily:"Inter",paddingTop:"20px",paddingLeft:"20%"}}>
             <span style={{fontSize:"22px"}}><Trans i18nKey="register.registertext"/></span>
             
            </div>
            <div style={{fontSize:"12px",paddingLeft:"20%",width:"100%"}}>Note: For the best user experience, please use a desktop or laptop computer.</div>
            <div class="card-body d-flex flex-column" style={{width:"83%",paddingLeft:"20%"}}>
              <div style={{width:"100%",color:"black",fontFamily:"Inter"}}>
                <Input
                       text={i18n.t('register.firstname')}
                       ref="firstName"
                       name="firstName"
                       validator={true}
                       value={this.state.firstName}
                       onChange={this.setFieldValue}
                     mandatory


                       emptyMessage="Application name can't be empty"
                     />
              </div>

              <div style={{width:"100%",color:"black",fontFamily:"Inter"}}>
                <Input
                              text={i18n.t('register.lastname')}
                              ref="lastName"
                              name="lastName"
                              // validator={true}
                              value={this.state.lastName}
                              onChange={this.setFieldValue}
                            // mandatory


                              // emptyMessage="Application name can't be empty"
                            />
              </div>

              <div style={{width:"100%",color:"black",fontFamily:"Inter"}}>
                <Input
                              text={i18n.t('register.emailid')}
                              ref="emailId"
                              name="emailId"
                              validator={true}
                              value={this.state.emailId}
                              onChange={this.setFieldValue}
                            mandatory


                              emptyMessage="Application name can't be empty"
                            />
              </div>

              <div style={{display:"flex",justifyContent:"left",alignItems:"left",flexDirection:"column",width:"100%",color:"black",fontFamily:"Inter"}}>
                <div class="input_group input_valid input_empty input_unfocused boxed" style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",width:"100%",color:"black",fontFamily:"Inter",paddingTop:"2%"}}>
                  <Input1
                                text={i18n.t('reset.password')}
                                ref="password"
                                name="password"
                                validator={true}
                                type={this.state.showPassword ? "text" : "password"}
                                style={{width:"100%",color:"black",fontFamily:"Inter",height:"100%",paddingLeft:"4%",marginBottom:"1%",outline:"none",margin:"auto"}}
                                value={this.state.password}
                                onChange={this.setFieldValue}
                              mandatory


                                emptyMessage="Application name can't be empty"
                              />
                              {/* *min length of password is 6 */}
                    <button style={{border:"0px",color:"gray",background:"transparent",cursor:"pointer",width:"10%",alignItems:"right"}} onClick={this.handleClickShowPassword}><img   src={this.eyeHandler()} height={20} /></button>
                </div>
              </div>

              <div style={{display:"flex",justifyContent:"left",alignItems:"left",flexDirection:"column",width:"100%",color:"black",fontFamily:"Inter"}}>
                <div class="input_group input_valid input_empty input_unfocused boxed" style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",width:"100%",color:"black",fontFamily:"Inter",paddingTop:"2%"}}>
                  <Input1
                                text={i18n.t('reset.confirmpwd')}
                                ref="pwd"
                                name="pwd"
                                validator={true}
                                type={this.state.showPassword1 ? "text" : "password"}
                                style={{width:"100%",color:"black",fontFamily:"Inter",height:"100%",paddingLeft:"4%",marginBottom:"1%",outline:"none",margin:"auto"}}
                                value={this.state.pwd}
                                onChange={this.setFieldValue}
                              mandatory


                                emptyMessage="Application name can't be empty"
                              />
                    <button style={{border:"0px",color:"gray",background:"transparent",cursor:"pointer",width:"10%",alignItems:"right"}} onClick={this.handleClickShowPassword1}><img   src={this.eyeHandler1()} height={20}/></button>
                </div>
              </div>

              <div style={{width:"100%",color:"black",fontFamily:"Inter"}}>              
               <ActionButton containerClass={`${this.props.isAuthenticating?"loading":null} btn btn-outline-theme`} clickHandler={()=>this.register()} style={{width:"100%",color:"white",background:"#66CBC9",textAlign:"center",fontFamily:"Inter"}}>Register</ActionButton>
              </div>

              <div style={{paddingTop:"3%",textAlign:"center"}}>
                    <a style={{border:"0px", textDecoration:"underline",color:"gray",background:"transparent",width:"100%",cursor:"pointer",fontFamily:"Inter"}} onClick={()=>this.loginUser()}><Trans i18nKey="login.login"/></a>
                    {/* {this.state.registerRequest==true?< Login_Form />:null} */}
                   
              </div> 
              
              <div class="error-msg" style={{height:"20%",paddingTop:"5%",textAlign:"center",border:"0px solid black"}}>
                {/* {!this.props.initialState && !this.props.isAuthenticated?<p style={{color:"#B22222"}}><Trans i18nKey="login.loginfailed"/></p>:null} */}
                {/* {this.props.sendingResetEmail?<ComponentLoader/>:null}wrongPassword */}
                {this.state.isRegisterError?<p style={{color:"#B22222"}}><Trans i18nKey="register.userExists"/></p>:null}
                {this.state.emptyPassword?<p style={{color:"#B22222"}}><Trans i18nKey="register.emptyPassword"/></p>:null}
                {this.state.passwordLength?<p style={{color:"#B22222"}}><Trans i18nKey="register.passwordLength"/></p>:null}
                {this.state.passwordMismatch?<p style={{color:"#B22222"}}><Trans i18nKey="register.passwordMismatch"/></p>:null}
                {this.state.emptyFirstname?<p style={{color:"#B22222"}}><Trans i18nKey="register.fillDetails"/></p>:null}
                {this.state.loading?<ComponentLoaderSmall/>:null}
                {/* {this.props.resetEmailError?<p style={{color:"#B22222"}}><Trans i18nKey="login.validusernamemsg"/></p>:null} */}
              </div>

              <div style={{width:"100%", display:"flex",flexDirection:"row",minHeight:"10%",paddingTop:"5%"}}>
                    <div style={{width:"50%",textAlign:"left"}}><a href="https://www.intelisa.in/" target="_blank" style={{ color:"gray",fontFamily:"Inter"}}><Trans i18nKey="login.aboutus"/></a></div>
                    <div style={{width:"50%",textAlign:"right"}}><a href="https://www.intelisa.in/#get-free-trial" target="_blank" style={{ color:"gray",fontFamily:"Inter"}}><Trans i18nKey="login.contactus"/></a></div>
              </div>

        </div>
        </div>
      </div>
      </div>
    );
  }
}
