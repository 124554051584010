import React, { Component ,Suspense,lazy } from 'react';
import { Switch, Route,Redirect } from 'react-router';
import IdleTimer from 'react-idle-timer';//for session timeout
import  IdleTimeOutModal from '../pages/Auth/IdleTimeOutModal';
import  Breakpoint  from '../components/uilib/Breakpoint'


import Navbar from "../components/navbars/NavbarBootAll"
import Footer from "../components/navbars/Footer"
import PageLoader from "../components/uilib/PageLoader"

const Dashboard = lazy(() => import('./Dashboard'));
const Admin = lazy(() => import('./Admin'));
const AdminOptions = lazy(() => import('./AdminOptions'));
const Report = lazy(() => import('./Report'));
const CanvaComponent = lazy(() => import('./CanvaComponent'));
const Screen_Admin = lazy(() => import('./Admin/Screen_Admin'));
const Assign_Screens = lazy(() => import('./Admin/Assign_Screens'));
const Access_Permissions = lazy(() => import('./Admin/Access_Permissions'));
const MyScreen = lazy(() => import('./MyScreen'));
const MyAds = lazy(() => import('./MyAds'));
const TagUpload = lazy(() => import('./TagUpload'));
const TodayPrice =  lazy(() => import('./TodayPrice'));
const ScreenDashboard = lazy(() => import('./ScreenDashboard'));
const Scheduler = lazy(() => import('./Scheduler'));
const Playlist = lazy(() => import('./Playlist'));
const Playlist_for_Approval = lazy(() => import('./Playlist_for_Approval'));
const DesignLayout = lazy(() => import('./DesignLayout'));
const Design = lazy(() => import('./Design'));
const DesignNew = lazy(() => import('./DesignNew'));
const OnOffReport = lazy(() => import('./OnOffReport'));
const WeeklyAdSummaryReport = lazy(() => import('./WeeklyAdSummaryReport'));
const CovidReport = lazy(() => import('./CovidReport'));
const CustomerReport = lazy(() => import('./CustomerReport'));
const CustomerDetailReport = lazy(() => import('./CustomerDetailReport'));
const PlaylistReport = lazy(() => import('./PlaylistReport'));
const AdReport = lazy(() => import("./AdReport"));
const TagReport = lazy(() => import("./TagReport"));
const AdGroup_Admin=lazy(() => import('./Admin/AdGroup_Admin'));
const Ad_Management=lazy(()=>import('./Admin/Ad_Management'));
const ScreenSaver_Management=lazy(()=>import('./Admin/ScreenSaver_Management'));
const SocialMedia_Management=lazy(()=>import('./Admin/SocialMedia_Management'));
const SocialMedia_Management_Review=lazy(()=>import('./Admin/SocialMedia_Management_Review'));
const User_Management=lazy(()=>import('./Admin/User_Management'));
const DeviceIP_Management=lazy(()=>import('./Admin/DeviceIP_Management'));
const SoftwareVersion_Update=lazy(()=>import('./Admin/SoftwareVersion_Update'));
const Schedule_Management=lazy(()=>import('./Admin/Schedule_Management'));
const SingleScreenSelector=lazy(()=>import('./Admin/SingleScreenSelector'));
const ScreenType_Management=lazy(()=>import('./Admin/ScreenType_Management'));
const Tags_Management = lazy(() => import('./Admin/Tags_Management'));
const Account_Management = lazy(() => import('./Admin/Account_Management'));
const DeleteAds = lazy(() => import('./Admin/DeleteAds'));
const Sync_Management = lazy(() => import('./Admin/Sync_Management'));
const Location_Management = lazy(() => import('./Admin/Location_Management'));
const ConfRoomScheduler = lazy(() => import('./ConfRoomScheduler'));
const GenerateGraph = lazy(() => import('./Admin/GenerateGraph'));
const Split_Screen_Management = lazy(() => import('./Admin/Split_Screen_Management'));
const LoginDetails = lazy(() => import('./Admin/LoginDetails'));
const TwoStepLogin = lazy(() => import('./Admin/TwoStepLogin'));
const Select_User_For_SWVersion = lazy(() => import('./Admin/Select_User_For_SWVersion'));
const Role_Management = lazy(() => import('./Admin/Role_Management'));
const Approval = lazy(() => import('./Admin/Approval'));


const Assign_Screen_SyncGroup = lazy(() => import('./Admin/Assign_Screen_SyncGroup'));
const Image_Editor = lazy(() => import('./Admin/Image_Editor')); //for testing Canva
const BoschUpload = lazy(() => import('./Admin/BoschUpload')); //for testing Canva
const SyncGroupLayout = lazy(() => import('./Admin/SyncGroupLayout'));
const ScreenLayoutSettings = lazy(() => import('./Admin/Screen_Layout_Settings'));
const AddWidgets = lazy(() => import('./Admin/Add_Widgets'));
const AddWidgetsManagement = lazy(() => import('./Admin/Add_Widgets_Management'));
const AnimationManagement = lazy(() => import('./Admin/AnimationManagement'));
const Android_App_Version = lazy(() => import('./Admin/Android_App_Version'));
const Contact_Us = lazy(() => import('./Admin/Contact_Us'));
const Online_Users = lazy(() => import('./Admin/Online_Users'));
const Registeruser = lazy(() => import('../components/Auth/Register_Form')); //Monika
 const MyPlans = lazy(() => import('../components/Auth/myplan'));
import Ad_Upload_Progress from "../components/admin/Ad_Upload_Progress";
import AccountSetup from "../components/accountsetup/AccountSetup";
import DialogBox from '../components/DialogBox1';

export default class Layout extends Component {
  constructor(props){
    super(props);
    this.isTimedOut= false
    this.state={
        builder:false,
        //for session timeout in milliseconds
        timeout:1000 * 10 * 60, //1000 * 10 * 60
        showModal: false,
        userLoggedIn: false,
        progressBarVisible:"hidden",
        openQuickAccountSetup:false,
        openStartSetup:false,
       
    }
        this.closeHandler = this.closeHandler.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        //for session timeout end
  }

  openProgressBar = (visibility) =>{
    console.log("setting Ad upload progress bar to visible!!")
    this.setState({progressBarVisible:visibility});
  }


  componentDidMount(){
    console.log("****IN Layout component did mount bypassStartSetup "+localStorage.getItem("bypassStartSetup"));

    if(JSON.parse(localStorage.getItem("userObj")).licensesTaken==0 &&
    JSON.parse(localStorage.getItem("userObj")).role=='001' 
    && !localStorage.getItem("bypassStartSetup")){
      console.log("***should open quick account setup!");
      this.setState({openStartSetup:true});
      //this.setState({openQuickAccountSetup:true});
    }
    
    const search = window.location.search;
    const params = new URLSearchParams(search);
    console.log("search in Layout="+search);
    const redirect = params.get("redirect");
    if(redirect!==null && redirect=="Myscreens"){
      console.log("***Redirecting!!!!")
      window.location.href=window.location.origin+"/#/myscreen/";
      //this.props.history.push({pathname:'/myscreen/',state:{option:'none'}});
    }

    //For redirecting from Azure Start
    const userObj = params.get("userObj");
    console.log("***userObj="+JSON.stringify(userObj))
    if(userObj!==null){
      var userObjToPutInLS = JSON.parse(userObj);
      console.log("****userObjToPutInLS="+JSON.stringify(userObjToPutInLS))
      localStorage.setItem("token",userObjToPutInLS.token)

      localStorage.setItem("userObj",JSON.stringify(userObjToPutInLS.userObject));
      window.location.href=window.location.origin+"/#";
      //window.location.reload();
    }
    const errorMessage = params.get("errorMessage");
    if(errorMessage!==null){
      var errorMessageToShow = JSON.stringify(errorMessage);
      console.log("***errorMessageToShow="+errorMessageToShow);
      window.location.href=window.location.origin+"/#/errorPage/"+errorMessage;
      
    }
    //For redirecting from Azure End
  }

  _onAction(e) {
    //console.log('user did something', e)
    this.isTimedOut=false
  }
 
  _onActive(e) {
    //console.log('user is active', e)
    this.isTimedOut=false
  }
 
  _onIdle(e) {
    //console.log('user is idle', e)
    const isTimedOut = this.isTimedOut
    if(!JSON.parse(localStorage.getItem("userObj")).emailId.includes('adani.com')){
      if (isTimedOut) {
        this.props.history.push('/logout')
      } else {
        this.setState({showModal: true})
        this.idleTimer.reset();
        this.isTimedOut=true
        // this.setState({isTimedOut: true})
      }
    }
    
    
  }
  closeHandler() {
   // console.log("in handle close")
    this.setState({showModal: false})
  }

  handleLogout() {
   // console.log("in handle logout")
    this.setState({showModal: false})
    this.props.history.push('/logout')
  }
closeAccountSetup = ()=>{
    console.log("in handle close")
    if(!localStorage.getItem("bypassStartSetup")){
      window.location.reload(true);
    }    
    //bypassStartSetup set to true to bypass creation of screen if screens not created
    localStorage.setItem("bypassStartSetup",true);
    this.setState({openQuickAccountSetup:false});
  }

  render() {


    return (
      <div class="h-100">
        <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />
        {this.state.showModal?
          <Breakpoint name="desktop">
        <IdleTimeOutModal 
                    closeHandler={()=>this.closeHandler()}
        handleLogout={()=>this.handleLogout()}
        /></Breakpoint>:null}
          <Navbar />

          <div class="content-wrapper no-scroll">
          <Suspense fallback={<PageLoader/>}>
          <Switch>

          <Route exact path="/overview" component={Dashboard}/>
   <Route path="/admin/screenmanagement" component={Screen_Admin} exact/>
   <Route path="/admin/adgroupmanagement" component={AdGroup_Admin} exact/>
   <Route path="/admin/accountmanagement" component={Account_Management} exact/>
   <Route path="/admin/admanagement/:selectedAd" component={Ad_Management} exact/> 
   {/* <Route path="/admin/admanagement" component={Ad_Management} exact/> */}
   <Route path="/admin/screensavermanagement" component={ScreenSaver_Management} exact/>
   <Route path="/admin/screentypemanagement" component={ScreenType_Management} exact/>
   <Route path="/admin/socialmediamanagement" component={SocialMedia_Management} exact/>
<Route path="/admin/socialmediamanagement_review" component={SocialMedia_Management_Review} exact/>
   <Route path="/adminoptions/schedulemanagementscreens" component={SingleScreenSelector} exact/>
   <Route path="/adminoptions/schedulemanagement/:screenId/:source" component={Schedule_Management} exact/>
   <Route path="/adminoptions/assignscreens" component={Assign_Screens} exact/>
   <Route path="/adminoptions/accesspermissions" component={Access_Permissions} exact/>
   <Route path="/adminoptions/assignSWversiontoscreens" component={Select_User_For_SWVersion} exact/>
   <Route path="/adminoptions/rolemanagement" component={Role_Management} exact/>
   <Route path="/adminoptions/approval" component={Approval} exact/>
   <Route path="/adminoptions/appsoftwareversion" component={Android_App_Version} exact/>
   <Route path="/adminoptions/contactus" component={Contact_Us} exact/>
   <Route path="/adminoptions/onlineusers" component={Online_Users} exact/>
   <Route path="/admin/tagsmanagement" component={Tags_Management} exact/>
   <Route path="/admin/deleteads" component={DeleteAds} exact/>
   <Route path="/admin/syncmanagement" component={Sync_Management} exact/>
   <Route path="/admin/locationmanagement" component={Location_Management} exact/>
   <Route path="/generateGraph" component={GenerateGraph} exact/>
   <Route path="/admin/splitscreenmanagement" component={Split_Screen_Management} exact/>
   <Route path="/admin/logindetails" component={LoginDetails} exact/>
   <Route path="/admin/twosteplogin" component={TwoStepLogin} exact/>
   <Route path="/admin/assignscreensyncgroup/:syncgroupname" component={Assign_Screen_SyncGroup} exact/>
   <Route path="/admin/imageeditor" component={Image_Editor} exact/>{/* Added for Canva */}
   <Route path="/admin/boschupload" component={BoschUpload} exact/>
      
   <Route path="/reports/onoffreport" component={OnOffReport} exact/>
   <Route path="/reports/weeklyadsummaryreport" component={WeeklyAdSummaryReport} exact/>
   <Route path="/reports/covidreport" component={CovidReport} exact/>
   <Route path="/reports/customerreport" component={CustomerReport} exact/>
   <Route path="/reports/customerdetailreport" component={CustomerDetailReport} exact/>
   <Route path="/reports/playlistreport" component={PlaylistReport} exact/>
   <Route path="/reports/adreport" component={AdReport} exact />
   <Route path="/reports/tagreport" component={TagReport} exact />
               
     {JSON.parse(localStorage.getItem("userObj")).role.toString()!="003"?
   <Route path="/adminoptions/usermanagement" component={User_Management} exact/>:null}
   {JSON.parse(localStorage.getItem("userObj")).role.toString()=="000"?
   <Route path="/admin/deviceIPmanagement" component={DeviceIP_Management} exact/>:null}
					 {JSON.parse(localStorage.getItem("userObj")).role.toString()=="000"?
   <Route path="/admin/softwareversionupdate" component={SoftwareVersion_Update} exact/>:null}

 <Route path="/admin" component={Admin} exact/>
 <Route path="/adminoptions" component={AdminOptions} exact/>
 <Route path="/scheduler" component={Scheduler} exact/>
 <Route path="/confroomscheduler" component={ConfRoomScheduler} exact/>
 {JSON.parse(localStorage.getItem("userObj")).permissions.includes("playlistapprovalneeded")?
  <Route path="/playlist" component={Playlist_for_Approval} exact/>
  :
  <Route path="/playlist" component={Playlist} exact/>
 }
 <Route path="/designlayout" component={DesignLayout} exact/>
 <Route path="/design" component={Design} exact/>
 <Route path="/designnew" component={DesignNew} exact/>
<Route path="/reports" component={Report} exact/>
<Route path="/tagupload" component={TagUpload} exact/>
<Route path="/todayprice" component={TodayPrice} exact/>
<Route path="/admin/syncgrouplayout/:syncgroupname" component={SyncGroupLayout} exact/>
 <Route path="/myscreen/" component={MyScreen} exact/>
<Route path="/canvacomp" component={CanvaComponent}/>
<Route path="/admin/screenlayoutsettings" component={ScreenLayoutSettings} exact/>
<Route path="/admin/addwidgets" component={AddWidgets} exact/>
<Route path="/admin/addwidgetsmanagement" component={AddWidgetsManagement} exact/>
<Route path="/admin/animationmanagement" component={AnimationManagement} exact/>
<Route path="/register" component={Registeruser} exact/>
<Route path="/myplans" component={MyPlans} exact/>
 {/* <Route path="/myads/" component={MyAds} exact/> */}
 <Route path="/myads/:selectedAd" render={props => <MyAds openProgressBar = {(visibility)=>this.openProgressBar(visibility)} />} exact/>
              {/* <Route path="/myscreen/:id/:screen/:slave/:ticker/:layout/:syncGroup/:zone/:screenType" component={ScreenDashboard} exact/> */}
			<Route path={"/myscreen/dashboard"} component={ScreenDashboard} exact/>
            <Redirect from='/' to={JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("overview")!==-1 ? '/overview':
            JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("myscreens")!==-1?'/myscreen':
            JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("myads")!==-1?'/myads/null':
            JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("playlist")!==-1?'/playlist':'/overview'}/>
          </Switch>
          </Suspense>
          </div>
         
{/* <div class="rounded" style={{backgroundColor:"#f5f3f3",zIndex:1500,position:"fixed",right:0,bottom:0,height:"25px",padding:5,width:"auto",maxWidth:"200px",minWidth:"100px"}}>
powered by&nbsp;<span style={{color:"#66CBC9",letterSpacing:"0.5px"}}>intel</span><span style={{color:"#B3272D",letterSpacing:"0.5px"}}>i</span><span style={{color:"#66CBC9",letterSpacing:"0.5px"}}>sa</span>
</div> */}
{/* {this.state.progressBarVisible=="visible"? */}
<Ad_Upload_Progress progressBarVisibility={this.state.progressBarVisible} openProgressBar = {(visibility)=>this.openProgressBar(visibility)} />
{this.state.openQuickAccountSetup?<AccountSetup closeHandler={()=>this.closeAccountSetup()}/>:null}
{this.state.openStartSetup?<DialogBox msg={"Let's start with a quick setup of your account."}  msg1={"We'll have you up and running in no time."}>
  <button class="btn btn-outline-theme" onClick={() => {this.setState({openStartSetup:false,openQuickAccountSetup:true})}} style={{width:"100%",color:"white",background:"#4fbfbd",textAlign:"center",fontFamily:"Inter"}}>Get Started</button> &nbsp;
  {/* <button class="btn btn-light btn-outline-theme" onClick={() => this.removeTour()} style={{width:"100%",color:"white",background:"#4fbfbd",textAlign:"right",fontFamily:"Inter"}}>Maybe Later</button> */}
</DialogBox>:null}

<Footer/>
      </div>
    );
  }
}
